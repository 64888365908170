<template>
  <div>
    <!-- page title ################### -->
    <div class="big-head">
      <v-layout align-center>
        <span>#{{$route.params.order}}</span>
        <v-flex grow>
          <h1>
            {{$route.params.name}}
            <em></em>
          </h1>
        </v-flex>
        <v-flex shrink>
          <!-- meio de pagamento ########### -->
          <InputSelect
            ref="companyGroupListInput"
            textLabel
            v-model="paymentMethod"
            :items="items"
            unselectedText="Todos"
            unselectedValue="0"
            valueAttribute="id"
            textAttribute="name"
            placeholder="Meio de Pagto"
          ></InputSelect>
        </v-flex>
        <v-flex shrink mr-3>
          <InputSelect
            class="ml-3"
            unselectedText="Visualização"
            unselectedValue="0"
            :items="[
              { id: 'institution', name: 'Instituição' },
              { id: 'campaign', name: 'Campanha' }
            ]"
            valueAttribute="id"
            textAttribute="name"
            v-model="typeView"
          />
        </v-flex>
        <v-flex shrink>
          <InputSelect
            class="ml-3"
            :items="[
              { id: 10, name: 10 },
              { id: 20, name: 20 },
              { id: 50, name: 50 },
              { id: 100, name: 100 },
            ]"
            valueAttribute="id"
            textAttribute="name"
            v-model="pageSize"
          />
        </v-flex>
      </v-layout>
    </div>
    <ListDonationsCashFlow
      :enableCheckbox="true"
      :enableDownloadExcelCsv="true"
      :enableModalLevel2="true"
      :enableTransfer="true"
      :enableOpenModalUser="false"
      :paginate="true"
      :level="3"
      :pageSize="pageSize"
      :typeView="typeView"
      :paymentMethod="paymentMethod"
      :statusConciliation="statusConciliation"
      :pageNumber="cashFlowPagesEnum.ConciliedAUC"
      :statusTransferResource="statusTransferResource.ConciliedByAuc"
      :enableConcilied="false"
      :selectOnlyOne="true"
    ></ListDonationsCashFlow>
  </div>
</template>
<script type="plain/text">
import ListDonationsCashFlow from "@/views/app/resourceFlow/ListDonationsCashFlow.vue";
import PaymentMethod from "@/scripts/models/enums/paymentMethod.enum";
import { CashFlowPages } from "@/scripts/models/enums/cashFlowPages.enum";
import DonationConciliationStatus from "@/scripts/models/enums/donationConciliationStatus.enum";
import { StatusTransferResource } from "@/scripts/models/enums/statusTransferResource.enum";

export default {
  components: {
    ListDonationsCashFlow
  },
  props: {
    pageOptions: null
  },
  data() {
    return {
      items: [
        { id: PaymentMethod.Ticket, name: "Boleto" },
        { id: PaymentMethod.CreditCard, name: "Cartão de Crédito" },
        { id: PaymentMethod.AccountDebit, name: "Débito em Conta" },
        { id: PaymentMethod.PayrollDeduction, name: "Débito em Folha" },
        { id: PaymentMethod.Pix, name: "Pix" }
      ],
      paymentMethod: PaymentMethod.PayrollDeduction,
      pageSize: 10,
      typeView: 'institution',
      cashFlowPagesEnum: "",
      statusConciliation: DonationConciliationStatus.ConciliedByAuc
    };
  },
  created() {
    this.cashFlowPagesEnum = CashFlowPages;
    this.statusTransferResource = StatusTransferResource;
    this.$emit("pageActive", CashFlowPages.ConciliedAUC);
  }
};
</script>
